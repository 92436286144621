function isEmpty(data) {
	return data === null || data === undefined || data === 'undefined' || data === '' || data === ' '
}

export default {
	install(Vue) {
		const methods = {
			isEmpty(data) {
				return data === null || data === undefined || data === 'undefined' || data === '' || data === ' '
			},
			clearMask(data) {
				return data.replace(/\D/g, '')
			},
			required: value => {
				return (value && value.length > 0) || 'Campo obrigatório'
			},
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Campo inválido'
			},
			emailOptional: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return value ? pattern.test(value) : true || 'Campo inválido'
			},
			match: (a, b) => {
				return a === b || 'Campos não são iguais'
			},
			alpha: value => {
				return (value && /^[A-Za-z\u00C0-\u00FF ]+$/.test(value)) || 'Campo só pode conter letras'
			},
			numeric: value => {
				return (value && /^[0-9 .]+$/.test(value)) || 'Campo só pode conter números'
			},
			alphaNumeric: value => {
				return (value && /^[A-Za-z\u00C0-\u00FF0-9 ]+$/.test(value)) || 'Campo só pode conter letras e números'
			},
			length: (value, length) => {
				return value.length >= length || `Campo só pode conter ${length} caracteres`
			},
			phone: value => {
				return !(value.length < 14) || `Campo deve conter um número de telefone válido`
			},
			choice: value => {
				return (!isEmpty(value.id) || !isEmpty(value._id)) || 'Campo obrigatório'
			},
			date: (value, required) => {
				if (required) {
					return (value && value.length === 10 && /^[0-9 /]+$/.test(value)) || 'Data inválida'
				}
				else {
					return (value.length > 0 ? (value.length === 10 && /^[0-9 /]+$/.test(value)) : true) || 'Data inválida'
				}
			}
		}

		Vue.prototype.$rules = methods
	}
}
