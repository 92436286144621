import Vue from 'vue'
import Vuex from 'vuex'

import userModule from './modules/user'
import videosModule from './modules/videos'
import productsModule from './modules/products'
import gamesModule from './modules/games'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		userModule,
		videosModule,
		productsModule,
		gamesModule
	}
})
