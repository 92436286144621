import config from '../../../../server/config'
import axios from 'axios'

let serverAPI = ''
if (config.isProduction) {
	serverAPI = ''
}
else {
	serverAPI = 'http://localhost:8081'
}

const api = {
	get: (path, payload, timeout) => {
		return new Promise((resolve, reject) => {
			let newPath = `${serverAPI}${path}`
			axios.get(newPath, { params: payload }, { timeout: timeout || 5000 }).then(response => {
				if (response && response.data && response.status === 200) {
					const content = response.data && response.data.status ? response.data : null
					resolve(content)
				}
				else {
					reject(response)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},
	post: (path, payload, timeout) => {
		return new Promise((resolve, reject) => {
			let newPath = `${serverAPI}${path}`
			axios.post(newPath, payload, { timeout: timeout || 5000 }).then(response => {
				if (response && response.data && response.status === 200) {
					const content = response.data && response.data.status ? response.data : null
					resolve(content)
				}
				else {
					reject(response)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},
	getExternal: (path, payload, timeout) => {
		return new Promise((resolve, reject) => {
			axios.get(path, { params: payload }, { timeout: timeout || 5000 }).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
}

export default api
