import router from '../../router/index'
import api from '../../plugins/core/api'

export default {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		fetchProducts({ commit }) {
			return new Promise((resolve, reject) => {
				api.get('/sales/get/products').then(result => {
					if (result && result.content && result.content.length > 0) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_PRODUCTS'))
					}
				}).catch(() => {
					reject()
				})
			})
		},
		checkoutSale({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/sales/checkout/sale', { data }, 60000).then(result => {
					if (result && result.content) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_PRODUCTS'))
					}
				}).catch(() => {
					reject()
				})
			})
		},
		registerSale({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/sales/put/sale', { data }, 60000).then(() => {
					resolve()
				}).catch(() => {
					reject()
				})
			})
		}
	}
}
