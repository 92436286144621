import Vue from 'vue'
import VueRouter from 'vue-router'
import api from '../plugins/core/api'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Landing',
		meta: {
			title: 'Facilitador do Autoconhecimento',
			auth: false
		},
		component: function () {
			return import(/* webpackChunkName: "landing" */ '../views/Landing.vue')
		}
	},
	{
		path: '/inicio',
		name: 'Main',
		meta: {
			title: 'Inicio',
			auth: true
		},
		component: function () {
			return import(/* webpackChunkName: "main" */ '../views/Main.vue')
		},
		children: [
			{
				path: '/inicio',
				name: 'Home',
				meta: {
					title: 'Inicio',
					auth: true
				},
				component: function () {
					return import(/* webpackChunkName: "home" */ '../views/Home.vue')
				}
			},
			{
				path: '/comprar',
				name: 'Buy',
				meta: {
					title: 'Comprar',
					auth: true
				},
				props: (route) => ({ transacao: route.query.transacao }),
				component: function () {
					return import(/* webpackChunkName: "buy" */ '../views/Buy.vue')
				}
			},
			{
				path: '/jogos/fac',
				name: 'FAC',
				meta: {
					title: 'Facilitador do Autoconhecimento',
					auth: true
				},
				component: function () {
					return import(/* webpackChunkName: "fac" */ '../views/games/FAC.vue')
				}
			},
			{
				path: '/jogos/fac/relatorio',
				name: 'ReportFAC',
				meta: {
					title: 'Relatório',
					auth: true
				},
				props: (route) => ({ id: route.query.id, u: route.query.u }),
				component: function () {
					return import(/* webpackChunkName: "reportfac" */ '../views/games/Reports.vue')
				}
			},
			{
				path: '/admin/clientes',
				name: 'AdminClients',
				meta: {
					title: 'Administração de Clientes',
					auth: true
				},
				component: function () {
					return import(/* webpackChunkName: "adminclients" */ '../views/admin/Clients.vue')
				}
			},
			{
				path: '/admin/produtos',
				name: 'AdminProducts',
				meta: {
					title: 'Administração de Produtos',
					auth: true
				},
				component: function () {
					return import(/* webpackChunkName: "adminproducts" */ '../views/admin/Products.vue')
				}
			},
		]
	},
	{
		path: '/entrar',
		name: 'Login',
		meta: {
			title: 'Entrar',
			auth: false
		},
		component: function () {
			return import(/* webpackChunkName: "login" */ '../views/Login.vue')
		}
	},
	{
		path: '/registrar',
		name: 'Register',
		meta: {
			title: 'Registrar',
			auth: false
		},
		component: function () {
			return import(/* webpackChunkName: "register" */ '../views/Register.vue')
		}
	},
	{
		path: '/redefinicao',
		name: 'ForgotPassword',
		meta: {
			title: 'Redefinição de Senha',
			auth: false
		},
		props: (route) => ({ token: route.query.token, id: route.query.id }),
		component: function () {
			return import(/* webpackChunkName: "forgot" */ '../views/Forgot.vue')
		}
	},
	{
		path: '*',
		name: '404',
		meta: {
			title: '404',
			auth: false
		},
		component: function () {
			return import(/* webpackChunkName: "404" */ '../views/errors/404.vue')
		}
	},
	{
		path: '/403',
		name: '403',
		meta: {
			title: '403',
			auth: false
		},
		component: function () {
			return import(/* webpackChunkName: "403" */ '../views/errors/403.vue')
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const hasAuth = to.matched.some(x => x.meta.auth)

	if (hasAuth) {
		const token = localStorage.getItem('fac-token') || sessionStorage.getItem('fac-token')
		if (token) {
			api.post('/users/auth', { token }).then(result => {
				if (result && result.content && result.content.id) {
					next()
				}
				else {
					localStorage.removeItem('fac-token')
					sessionStorage.removeItem('fac-token')
					next('/entrar')
				}
			}).catch(() => {
				localStorage.removeItem('fac-token')
				sessionStorage.removeItem('fac-token')
				next('/entrar')
			})
		}
		else {
			next('/entrar')
		}
	}
	else {
		next()
	}
})

export default router
