// MAIN SERVER SETTINGS
const isProduction = true

module.exports = {
	isProduction,
	server: {
		host: isProduction ? '191.252.178.216' : 'localhost',
		port: 80,
		cors: isProduction ? 'http://191.252.178.216' : 'http://localhost:80'
	}
}
