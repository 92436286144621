import router from '../../router/index'
import api from '../../plugins/core/api'

export default {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		fetchGameFACParameters({ commit }) {
			return new Promise((resolve, reject) => {
				api.get('/game/fac/parameters').then(result => {
					if (result && result.content && result.content.id) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAME'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		fetchUserGameProgress({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.get('/game/fac/user/progression', { id: data }).then(result => {
					if (result && result.content && result.content.length > 0) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAME'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		fetchGameFAC({ commit }) {
			return new Promise((resolve, reject) => {
				api.get('/game/fac/complete').then(result => {
					if (result && result.content && result.content.data) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAME'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		fetchGameFACReport({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/complete/report', { data }).then(result => {
					if (result && result.content && result.content.data) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAME'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		setUserGameProgression({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/user/progression/set', { data }).then(() => {
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		setUserGameFinished({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/user/progression/finish', { data }, 60000).then(() => {
					router.push('/inicio')
				}).catch(error => {
					reject(error)
				})
			})
		},
		fetchUserGameRecord({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/user/report', { data }).then(result => {
					if (result && result.content && result.content.id) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAME'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		fetchAllUserGames({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/admin/all', { id: data }).then(result => {
					if (result && result.content && result.content.length > 0) {
						resolve(result.content)
					}
					else {
						reject(new Error('NO_RESULT_GAMES'))
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		setUserGameAccessType({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.post('/game/fac/admin/access/set', { data }, 60000).then(() => {
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
	}
}