<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
export default {
	name: "App",
	data: () => ({}),
	mounted() {
		this.setTitle()
	},
	methods: {
		setTitle() {
			if (this.$route.meta && this.$route.meta.title) {
				document.title = this.$route.meta.title + ' - FAC'
			}
			else {
				document.title = 'FAC'
			}
		}
	},
	watch: {
		'$route'() {
			this.setTitle()
		}
	}
}
</script>
