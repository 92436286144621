import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import validator from './plugins/core/validator'
import EventBus from './plugins/core/eventbus'
import VueTheMask from 'vue-the-mask'
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto'

Vue.config.productionTip = false

// PLUGINS
Vue.use(validator)
Vue.use(EventBus)

// MASKING
Vue.use(VueTheMask)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')