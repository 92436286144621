// import * as fb from '../../firebase'

export default {
	state: {
		reportVideos: {}
	},
	getters: {
		getReportVideos(state) {
			return state.reportVideos
		}
	},
	mutations: {
		setReportVideos(state, val) {
			state.reportVideos = val
		}
	},
	actions: {
		async fetchReportVideos({ commit }) {
			try {
				const videosTable = await fb.videosCollection.doc('jK46Z9MX4ewp6TiRYbI3').get()
				const videoLinks = await videosTable.data()

				if (videoLinks && videoLinks.links && videoLinks.links.length > 0) {
					commit('setReportVideos', videoLinks.links)
				}
			}
			catch {
				commit('setReportVideos', {})
				throw 'ERROR_FETCHING_REPORT_VIDEOS'
			}
		},
		fetchReportVideosPromise({ commit }) {
			return new Promise(async (resolve, reject) => {
				const videosTable = await fb.videosCollection.doc('jK46Z9MX4ewp6TiRYbI3').get()
				const videoLinks = await videosTable.data()

				if (videoLinks && videoLinks.links && videoLinks.links.length > 0 && videoLinks.grupos && videoLinks.grupos.length > 0) {
					resolve({
						intro: videoLinks.introducao,
						links: videoLinks.links,
						grupos: videoLinks.grupos
					})
				}
				else {
					reject('ERROR_FETCHING_REPORT_VIDEOS')
				}
			})
		},
	}
}
