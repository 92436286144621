// import * as fb from '../../firebase'
import router from '../../router/index'
import api from '../../plugins/core/api'

export default {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
		checkAuth({ commit }) {
			return new Promise((resolve, reject) => {
				const token = localStorage.getItem('fac-token') || sessionStorage.getItem('fac-token')
				api.post('/users/auth', { token }).then(result => {
					if (result && result.content && result.content.id) {
						resolve(result)
					}
				}).catch(() => {})
			})
		},
		getUserSimpleData({ commit }, id) {
			return new Promise((resolve, reject) => {
				api.get('/users/simple', { id }).then(result => {
					if (result && result.content && result.content.id) {
						resolve(result)
					}
				}).catch(() => {})
			})
		},
		checkAuthAdmin({ commit }) {
			return new Promise((resolve, reject) => {
				const token = localStorage.getItem('fac-token') || sessionStorage.getItem('fac-token')
				api.post('/users/auth/admin', { token }).then(result => {
					if (result.status !== 200) {
						reject()
					}
					else {
						resolve(result.content)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},
		doLogin({ commit }, data) {
			return new Promise((resolve, reject) => {
				if (data && data.email) {
					api.post('/users/login', { data }).then(result => {
						if (result) {
							if (result.content && result.content.token) {
								localStorage.setItem('fac-token', result.content.token)
								resolve()
							}
							else if (result.status !== 200) {
								reject(result)
							}
						}
						else {
							reject(new Error('NO_RESULT_AFTER_LOGIN'))
						}
					}).catch(error => {
						reject(error)
					})
				}
				else {
					reject(new Error('NO_DATA_TO_LOGIN'))
				}
			})
		},
		doRegister({ commit }, data) {
			return new Promise((resolve, reject) => {
				if (data && data.email) {
					api.post('/users/register', { data }, 60000).then(result => {
						if (result) {
							if (result.content && result.content.token) {
								localStorage.setItem('fac-token', result.content.token)
								resolve()
							}
							else if (result.status !== 200) {
								reject(result)
							}
						}
						else {
							reject(new Error('NO_RESULT_AFTER_REGISTER'))
						}
					}).catch(error => {
						reject(error)
					})
				}
				else {
					reject(new Error('NO_DATA_TO_REGISTER'))
				}
			})
		},
		doLogout({ commit }, data) {
			return new Promise((resolve, reject) => {
				if (data && data.id) {
					api.post('/users/logout', { id: data.id }).then(() => {
						localStorage.removeItem('fac-token')
						sessionStorage.removeItem('fac-token')
						router.push('/')
					}).catch(error => {
						reject(error)
					})
				}
				else {
					reject(new Error('NO_DATA_TO_LOGOUT'))
				}
			})
		},
		doForgotPassword({ commit }, data) {
			return new Promise((resolve, reject) => {
				if (data && data.email) {
					api.post('/users/password/forgot', { data }, 60000).then(() => {
						resolve()
					}).catch(error => {
						reject(error)
					})
				}
				else {
					reject(new Error('NO_DATA_TO_LOGOUT'))
				}
			})
		},
		doResetPassword({ commit }, data) {
			return new Promise((resolve, reject) => {
				if (data && data.id) {
					api.post('/users/password/reset', { data }, 60000).then(() => {
						resolve()
					}).catch(error => {
						reject(error)
					})
				}
				else {
					reject(new Error('NO_DATA_TO_LOGOUT'))
				}
			})
		}
	}
}
